<template>
    
    <div class="">
        
        <div class="mx-auto container text-center pt-20">
            <!-- <h2 class="text-6xl font-bold text-blue-900 leading-tight"> -->
			<h2 class="text-6xl font-bold tracking-none leading-none max-w-3xl mx-auto text-blue-900">
                {{$t('product.parts.heading')}}
            </h2>
            <p class="max-w-lg inline-block mt-8 ">
                {{$t('product.parts.intro')}}
            </p>
        </div>
        
        <div class="grid grid-cols-2 gap-16 mb-12">
            
            <section class="bg-white rounded-tr-xl flex justify-end p-12">
                <div class="max-w-md">
                    <router-link :to="{ name: 'product-handheld' }">
                        <img src="/images/frontpage/cloudcast_handheld_sparkle.png"/>
                    </router-link>
                    
                    <div class="">
                        <router-link :to="{ name: 'product-handheld' }">
                            <h2 class="h2 mb-2 text-blue-900">
                                {{$t('product.handheld.heading')}}
                            </h2>
                        </router-link>
                        
                        <p>
                            {{$t('product.handheld.intro')}}
                        </p>
                    </div>
                    
                    <router-link 
                        :to="{ name: 'product-handheld' }"
                        class="link mt-4 inline-block">
                        
                        {{$t('general.moreDetails')}}
                    </router-link>
                </div>
            </section>
            
            <section class="bg-white rounded-tl-xl flex justify-start p-12">
                <div class="max-w-md">
                    <router-link :to="{ name: 'product-admin' }">
                        <img src="/images/frontpage/cloudcast_admin_sparkle.png"/>
                    </router-link>
                    
                    <div class="">
                        <router-link :to="{ name: 'product-admin' }">
                            <h2 class="h2 mb-2 text-blue-900">
                                {{$t('product.admin.heading')}}
                            </h2>
                        </router-link>
                        
                        <p>
                            {{$t('product.admin.intro')}}
                        </p>
                    </div>
                    
                    <router-link 
                        :to="{ name: 'product-admin' }"
                        class="link mt-4 inline-block">
                        
                        {{$t('general.moreDetails')}}
                    </router-link>
                </div>
            </section>
            
        </div>
        
        <div class="bg-gray-100 overflow-hidden">
            <div class="mx-auto container">
                <SpeakToExpert />
            </div>
        </div>
        
    </div>
    
</template>

<script>
export default {
}
</script>

<style lang="scss" >
</style>
